import css1 from '../img/portImages/Capture.PNG';
import img2 from '../img/portImages/quiz.PNG';
import img3 from '../img/portImages/Tour.PNG';
import img4 from '../img/portImages/plus.PNG';
import img5 from '../img/portImages/Experince.PNG';
import img6 from '../img/portImages/Tigers.PNG';
import img7 from '../img/portImages/menu.PNG';
import img8 from '../img/portImages/todo.PNG';
import img9 from '../img/portImages/portfolio.PNG';
import img10 from '../img/portImages/ms.png';
import img11 from '../img/portImages/pm.PNG';
import img12 from '../img/portImages/todoapp.PNG';
import img13 from '../img/portImages/greatestpizza.PNG';


const portfolios = [
    {
        id: 1,
        category: 'React JS',
        image: img11,
        link1: 'https://github.com/ibrohimsoft/pizzareact',
        link2: 'https://pizzareact.vercel.app/',
        title: 'Moestro Pizza',
        text: 'React Js'
    },
    {
        id: 2,
        category: 'React JS',
        image: img12,
        link1: 'https://github.com/ibrohimsoft/todoreact',
        link2: 'https://todoreactjs.vercel.app/',
        title: 'Todo',
        text: 'Created with only React Js.'
    },
    {
        id: 3,
        category: 'React JS',
        image: img13,
        link1: 'https://github.com/ibrohimsoft/reactpizzas',
        link2: 'https://reactpizzas.vercel.app/',
        title: 'GREATEST PIZZA EVER',
        text: 'Created with only HTML CSS and React Js.'
    },
    {
        id: 4,
        category: 'Javascript',
        image: img2,
        link1: 'https://github.com/ibrohimsoft/quiz',
        link2: 'https://quiztest1.netlify.app/',
        title: 'Test',
        text: 'Created with only HTML CSS and Javascript.'
    },
    {
        id: 5,
        category: 'React JS',
        image: css1,
        link1: 'https://github.com/ibrohimsoft/menu',
        link2: 'https://ibrohim-react-projects1.vercel.app/',
        title: 'Simple Menu',
        text: 'Created with React.'
    },
    {
        id: 6,
        category: 'React JS',
        image: img3,
        link1: 'https://github.com/ibrohimsoft/react-menu',
        link2: 'https://ibrohim-react-projects2.vercel.app/',
        title: 'Tour menu',
        text: 'React JS class components'
    },
    {
        id: 7,
        category: 'React JS',
        image: img4,
        link1: 'https://www.google.com',
        link2: 'https://note-proo.vercel.app/',
        title: 'How To Animate click button',
        text: 'Number one Animation Application'
    },
    {
        id: 8,
        category: 'React JS',
        image: img5,
        link1: 'https://www.google.com',
        link2: 'https://react-pro-tabs.vercel.app/',
        title: 'How To Use Experience',
        text: 'Free Animation Software'
    },
    {
        id: 9,
        category: 'Javascript',
        image: img6,
        link1: 'https://github.com/ibrohimsoft/menu-boxing',
        link2: 'https://menu-boxing.vercel.app/',
        title: 'Menu Boxing',
        text: 'Html Css and Js'
    },
    {
        id: 10,
        category: 'React JS',
        image: img7,
        link1: 'https://github.com/ibrohimsoft/menu-cafe',
        link2: 'https://menu-cafe-pro.vercel.app/',
        title: 'Foods menu',
        text: 'Created using different technologies such as Material UI, Styled-Components and more...'
    },
    {
        id: 11,
        category: 'React JS',
        image: img8,
        link1: 'https://github.com/ibrohimsoft/bud-pro',
        link2: 'https://bud-lac.vercel.app/',
        title: 'Grocery Bud',
        text: 'Created using HTML CSS Js and React'
    },
    {
        id: 12,
        category: 'Javascript',
        image: img9,
        link1: 'https://github.com/ibrohimsoft/javascript-portfolio',
        link2: 'https://ibrohim-r-portfolio.netlify.app/',
        title: 'Portfolio',
        text: 'Html Css and Js'
    },
    {
        id: 13,
        category: 'Css',
        image: img10,
        link1: 'https://github.com/ibrohimsoft/AnimationCss',
        link2: 'https://animation-css-six.vercel.app/',
        title: 'Buttons',
        text: 'Animation css'
    },
]

export default portfolios;